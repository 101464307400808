import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Member from '../pages/Member/ListMember'
import {CreateServices} from '../pages/channel/CreateServices'
import {Explore} from '../pages/explore/Explore'
import {ServiceTree} from '../pages/channel/ServiceTree'
import ServicesOne from '../pages/channel/levels/ServicesOne'
import ServicesTwo from '../pages/channel/levels/ServicesTwo'
import ServicesThree from '../pages/channel/levels/ServicesThree'
import IconsListing from '../pages/icons/IconsListing'
import Configuration from '../pages/configuration'
import MemberPage from '../pages/Member/MemberPage'

import {ViewConfig} from '../pages/configuration/components/ViewConfig'
import {Appointments} from '../pages/Appointment/ListAppointment'
import {ViewAppointments} from '../pages/Appointment/components/ViewAppointments'
import {UsersList} from '../pages/users/UsersList'
import CreateUser from '../pages/users/CreateUser'
import FAQ from '../pages/faq/FAQ'
import CreateFaq from '../pages/faq/CreateFaq'
import ViewFaq from '../pages/faq/ViewFaq'
import AppointmentHistory from '../pages/Appointment/components/AppointmentHistory'
import ListMember from '../pages/Member/ListMember'
import NewMemberProfile from '../pages/Member/components/NewMemberProfile'
import ListProvider from '../pages/Provider/ListProvider'
import NewProfile from '../pages/Provider/components/NewProfile'
import {TransactionsList} from '../pages/transactionTypes/TransactionsList'
import CreateTransaction from '../pages/transactionTypes/CreateTransaction'
import ViewTransaction from '../pages/transactionTypes/ViewTransaction'
import {PaymentsSummary} from '../pages/paymentsSummary/PaymentsSummary'
import ServiceIdentifiers from '../pages/serviceIdentifiers/ServiceIdentifiers'
import ListContentProvider from '../pages/Provider/ListContentProvider'

const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))
const ProviderPage = lazy(() => import('../pages/Provider/ProviderPage'))
const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route
          path='/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='/crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* ************************** providers starts here ********************************************** */}

        <Route
          path='/providers'
          element={
            <SuspensedView>
              <ListProvider />
            </SuspensedView>
          }
        />
        <Route
          path='/favorites'
          element={
            <SuspensedView>
              <ListContentProvider />
            </SuspensedView>
          }
        />
        <Route
          path='/providers/:Id'
          element={
            <SuspensedView>
              <NewProfile />
            </SuspensedView>
          }
        />
        <Route
          path='/favorites/:Id'
          element={
            <SuspensedView>
              <NewProfile />
            </SuspensedView>
          }
        />

        {/* ************************** members starts here ********************************************** */}

        <Route
          path='/members'
          element={
            <SuspensedView>
              <ListMember />
            </SuspensedView>
          }
        />
        <Route
          path='/members/:Id'
          element={
            <SuspensedView>
              <NewMemberProfile />
            </SuspensedView>
          }
        />

        {/* <Route
          path='/members'
          element={
            <SuspensedView>
              <Member />
            </SuspensedView>
          }
        />
        <Route
          path='/members/profile/:Id'
          element={
            <SuspensedView>
              <ViewMembers />
            </SuspensedView>
          }
        />
        <Route
          path='/members/team/:Id'
          element={
            <SuspensedView>
              <MembersTeam />
            </SuspensedView>
          }
        />
        <Route
          path='/members/appointments/:Id'
          element={
            <SuspensedView>
              <MembersAppointments />
            </SuspensedView>
          }
        />
        <Route
          path='/members/connection/:Id'
          element={
            <SuspensedView>
              <MembersConnection />
            </SuspensedView>
          }
        />
        <Route
          path='/members/payment/:Id'
          element={
            <SuspensedView>
              <MembersPayment />
            </SuspensedView>
          }
        /> */}
        {/* ************************** services starts here ********************************************** */}
        <Route
          path='/channels'
          element={
            <SuspensedView>
              <ServicesOne />
            </SuspensedView>
          }
        />
        <Route
          path='/channels/lv1/:oneId'
          element={
            <SuspensedView>
              <ServicesTwo />
            </SuspensedView>
          }
        />
        <Route
          path='/channels/lv1/:oneId/lv2/:twoId'
          element={
            <SuspensedView>
              <ServicesThree />
            </SuspensedView>
          }
        />

        <Route
          path='/channels/create'
          element={
            <SuspensedView>
              <CreateServices />
            </SuspensedView>
          }
        />

        <Route
          path='/channels/update/:Id'
          element={
            <SuspensedView>
              <CreateServices />
            </SuspensedView>
          }
        />

        {/* ************************** icons starts here ********************************************** */}
        <Route
          path='/icons'
          element={
            <SuspensedView>
              <IconsListing />
            </SuspensedView>
          }
        />

        {/* ************************** users starts here ********************************************** */}
        <Route
          path='/users'
          element={
            <SuspensedView>
              <UsersList />
            </SuspensedView>
          }
        />
        <Route
          path='/users/create'
          element={
            <SuspensedView>
              <CreateUser />
            </SuspensedView>
          }
        />

        {/* ************************** appointment starts here ********************************************** */}
        <Route
          path='/appointments'
          element={
            <SuspensedView>
              <Appointments />
            </SuspensedView>
          }
        />
        <Route
          path='/appointments/:id'
          element={
            <SuspensedView>
              <ViewAppointments />
            </SuspensedView>
          }
        />
        <Route
          path='/appointments/:id/history'
          element={
            <SuspensedView>
              <AppointmentHistory />
            </SuspensedView>
          }
        />

        <Route
          path='/feed'
          element={
            <SuspensedView>
              <Explore />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='configurations'
          element={
            <SuspensedView>
              <Configuration />
            </SuspensedView>
          }
        />
        <Route
          path='configurations/view'
          element={
            <SuspensedView>
              <ViewConfig />
            </SuspensedView>
          }
        />

        {/* ************************** faq starts here ********************************************** */}
        <Route
          path='/faqs'
          element={
            <SuspensedView>
              <FAQ />
            </SuspensedView>
          }
        />
        <Route
          path='/faqs/create'
          element={
            <SuspensedView>
              <CreateFaq />
            </SuspensedView>
          }
        />
        <Route
          path='/faqs/:id'
          element={
            <SuspensedView>
              <ViewFaq />
            </SuspensedView>
          }
        />

        {/* ************************** transaction type starts here ********************************************** */}
        <Route
          path='/transaction-types'
          element={
            <SuspensedView>
              <TransactionsList />
            </SuspensedView>
          }
        />
        <Route
          path='/transaction-types/create'
          element={
            <SuspensedView>
              <CreateTransaction />
            </SuspensedView>
          }
        />
        <Route
          path='/transaction-types/:id'
          element={
            <SuspensedView>
              <ViewTransaction />
            </SuspensedView>
          }
        />

        {/* ************************** transaction type starts here ********************************************** */}
        <Route
          path='/payments-summary'
          element={
            <SuspensedView>
              <PaymentsSummary />
            </SuspensedView>
          }
        />

        {/* ************************** service-identifiers starts here ********************************************** */}
        <Route
          path='/service-identifiers'
          element={
            <SuspensedView>
              <ServiceIdentifiers />
            </SuspensedView>
          }
        />

        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
