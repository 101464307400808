import React, {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useProviderSList, useProviderStatusUpdate} from './hooks/useProviderQuery'
import {ToggleState} from './modals/type'

import {DeleteIcon, LockIcon, ViewIcon} from '../../modules/common/TableIcons'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import Verified from '../../modules/common/Verified'
import {DeleteModal, PasswordModal} from './modals'
import {
  getConfigurationByKeyAction,
  singleConfigurationSelector,
} from '../../reducers/configurationReducer'
import {useAppDispatch} from '../../store'
import {useSelector} from 'react-redux'
import {statusSelector} from '../../reducers/providerReducer'
import MUITable from '../../modules/common/MUITable'
import {SwitchTextTrack} from '../../../config'
import heic2any from 'heic2any'

type ProviderRow = {
  profilePic: string
  firstName: string
  lastName: string
  phone: string
  businessName: string
  status: string
  id: string
  timezone: string
  isVerified: boolean
}

const imageCache = new Map<string, string>()

const ProfileImage = ({imageUrl}: {imageUrl: string}) => {
  const [imgSrc, setImgSrc] = useState(
    imageCache.get(imageUrl) || '/media/icons/duotune/communication/com006.svg'
  )

  useEffect(() => {
    const convertHeicToJpeg = async (url: string) => {
      try {
        // Return cached version if available
        if (imageCache.has(url)) {
          setImgSrc(imageCache.get(url)!)
          return
        }

        if (url?.toLowerCase().endsWith('.heic')) {
          const response = await fetch(url)
          const blob = await response.blob()
          const conversionResult = await heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.1, // Reduced quality for better performance
          })
          const jpegUrl = URL.createObjectURL(conversionResult as Blob)
          imageCache.set(url, jpegUrl) // Cache the converted image
          setImgSrc(jpegUrl)
        } else {
          imageCache.set(url, url)
          setImgSrc(url)
        }
      } catch (error) {
        console.error('Error converting HEIC:', error)
        setImgSrc('/media/icons/duotune/communication/com006.svg')
      }
    }

    if (imageUrl) {
      convertHeicToJpeg(imageUrl)
    }
  }, [imageUrl])

  return (
    <img
      src={imgSrc}
      alt=''
      className='rounded-circle h-40px w-40px'
      onError={(e: any) => {
        e.target.src = '/media/icons/duotune/communication/com006.svg'
      }}
    />
  )
}

export default function ListContentProvider() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const allowDelete: boolean = queryParams.get('super') === 'true'
  const dispatch: any = useAppDispatch()
  const [userData, setUserData] = useState<any>()
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [search, setSearch] = useState('')

  const [toggle, setToggle] = useState<ToggleState[]>([
    {state: false, name: 'd-modal'},
    {state: false, name: 'pass-modal'},
  ])

  useEffect(() => {
    dispatch(getConfigurationByKeyAction('provider_approval_flow_bypass'))
    return () => {}
  }, [])

  const configuration = useSelector(singleConfigurationSelector)
  const status = useSelector(statusSelector)
  // console.log('conf', configuration)

  const {isLoading: statusLoading, mutate} = useProviderStatusUpdate()

  const initTable = {
    columns: [
      {id: 'profilePic', label: '', numeric: false, disablePadding: true},
      {
        id: 'businessName',
        label: 'Business name',
        // align: 'right',
        numeric: false,
        disablePadding: true,
      },
      //   {id: 'firstName', label: 'First name', numeric: false, disablePadding: true},
      //   {id: 'lastName', label: 'Last name', numeric: false, disablePadding: true},
      {
        id: 'phone',
        label: 'Landline number',
        // align: 'right',
        numeric: true,
        disablePadding: true,
      },
      // {
      //   id: 'timezone',
      //   label: 'Timezone',
      //   // align: 'right',
      //   numeric: false,
      //   disablePadding: true,
      // },
      // {
      //   id: 'status',
      //   label: 'Status',
      //   // align: 'right',
      //   numeric: false,
      //   disablePadding: true,
      // },
      {
        id: 'action',
        label: 'Action',
        // align: 'right',
        numeric: false,
        disablePadding: true,
      },
    ],
    rows: [],
  }

  function handleOpenModal(modal: string, itemId: string) {
    setToggle((prev) => {
      let modalState = prev.map((curState) => {
        if (curState.name === modal) {
          curState.state = true
          curState.id = itemId
        }
        return curState
      })
      return modalState
    })
  }

  const rowItem = (item: ProviderRow, data: any) => ({
    profilePic: <ProfileImage imageUrl={item?.profilePic} />,
    businessName: item.businessName,
    //   firstName: [<Verified isVerified={item.isVerified} />, item.firstName],
    //   lastName: item.lastName,
    phone: item.phone,
    // timezone: item.timezone,
    // status: [
    //   statusLoading || loading ? (
    //     id === item?.id ? (
    //       <div className='ms-1'>
    //         <span className='spinner-border align-middle'></span>
    //       </div>
    //     ) : (
    //       <SwitchTextTrack
    //         checked={item?.status === 'approved' ? true : false} // Handle checkbox checked state
    //       />
    //     )
    //   ) : (
    //     <SwitchTextTrack
    //       onChange={(e) => {
    //         if (configuration?.value?.status === 1) {
    //           mutate({
    //             providerId: item?.id,
    //             status: item?.status === 'approved' ? 'pending' : 'approved',
    //           })
    //           setId(item?.id)
    //         }
    //       }}
    //       disabled={configuration?.value?.status !== 1 ? true : false}
    //       checked={item?.status === 'approved' ? true : false} // Handle checkbox checked state
    //     />
    //   ),
    // ],
    action: [
      <ViewIcon
        path={data?.businessName ? `/favorites/${item.id}` : '#'}
        data={data}
        active={data?.businessName ? true : false}
      />,
      // <EditIcon path='#' />,
      // <LockIcon
      //   byClick={() => {
      //     handleOpenModal('pass-modal', item.id)
      //     setUserData(item)
      //   }}
      //   path='#'
      // />,
      allowDelete && <DeleteIcon byClick={() => handleOpenModal('d-modal', item.id)} path='#' />,
    ],
  })

  // const handleStatusUpdate = (data: any) => {
  //   dispatch(updateProviderStatusAction(data))
  //   refetch()
  // }

  const listSelector = (res: any) => {
    let rows: never[] = []
    let pagination
    // console.log(res)

    pagination = {
      total: res.total,
      lastPage: res.lastPage,
    }

    res?.providers?.forEach((element: any) => {
      const item: ProviderRow = {
        profilePic: element?.profileImage,
        firstName: element?.firstName,
        lastName: element?.lastName,
        phone: element?.mobileNo,
        businessName: element?.businessName,
        status: element?.status,
        timezone: element?.timezone,
        isVerified: element?.isMobileVerified,
        id: element?.id,
      }
      rows.push(rowItem(item, element) as never)
    })

    let table = initTable
    table.rows = rows
    return {table, pagination}
  }

  const {isLoading, data, isFetching} = useProviderSList({
    func: listSelector,
    params: {
      page,
      perPage,
      q: search,
      isTypeContent: 1,
    },
  })

  // console.log('data', data)

  useEffect(() => {
    setLoading(isFetching)
  }, [isFetching])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <h2 className='py-3 mb-2'>Favorites</h2>
          <div>
            <input
              type='search'
              onChange={(e) => {
                if (e.target.value.length >= 3) {
                  setSearch(e.target.value)
                }
                if (e.target.value === '') {
                  setSearch(e.target.value)
                }
              }}
              placeholder='Search'
              className='form-control form-control-sm'
            />
          </div>
        </div>
        <div className='col-6 align-self-center text-end'>
          {/* <button type="button" className="btn btn-primary">
              Create
            </button> */}
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {isLoading ? (
          <TableLoading />
        ) : (
          <MUITable
            data={data}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        )}
      </div>

      {toggle[0].state && <DeleteModal onHide={setToggle} toggle={toggle[0]} />}
      {toggle[1].state && (
        <PasswordModal onHide={setToggle} toggle={toggle[1]} userData={userData} />
      )}
    </>
  )
}
