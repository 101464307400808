import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import {Pagination} from '@mui/material'

export default function MUITable({data, page, perPage, setPage, setPerPage}: any) {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(+event.target.value)
    setPage(1)
  }

  React.useEffect(() => {
    setPage(data?.pagination.lastPage === 1 ? data.pagination.lastPage : page)
  }, [data?.pagination, page, setPage])

  //   console.log('data', data)

  const rows = React.useMemo(() => {
    return data?.table.rows
  }, [data])

  return (
    <Paper sx={{width: '100%', overflow: 'hidden'}}>
      <TableContainer>
        <Table aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {data?.table.columns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{minWidth: column.minWidth, fontWeight: 'bold', fontSize: '12px'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any) => {
              // console.log('row', row)
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                  {data?.table.columns.map((column: any) => {
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={data.pagination.total || 0}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <div className='d-flex justify-content-end align-items-center'>
        <div className='d-flex align-items-center'>
          <label htmlFor=''>Rows per page :</label>
          <select
            className='form-control p-2 border-0'
            value={perPage}
            onChange={handleChangeRowsPerPage}
          >
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='25'>25</option>
          </select>
        </div>
        <div>
          <Pagination
            count={data?.pagination.lastPage || 0}
            onChange={handleChangePage}
            page={page}
          />
        </div>
      </div>
    </Paper>
  )
}
