/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import {FC, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Box} from '@mui/system'
import {Modal} from '@mui/material'
import {useProviderMedia} from '../hooks/useProviderQuery'
import {makeDate} from '../../../../util'
import {NA} from '../../../../config/constant'
import heic2any from 'heic2any'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 'auto',
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
}

type Props = {
  //   map: any
}

type Profile = {
  profileImg: string
  tags: string
}

const imageCache = new Map<string, string>()

const ProfileImage = ({imageUrl}: {imageUrl: string}) => {
  const [imgSrc, setImgSrc] = useState(
    imageCache.get(imageUrl) || '/media/icons/duotune/communication/com006.svg'
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const convertHeicToJpeg = async (url: string) => {
      if (!url) return

      if (imageCache.has(url)) {
        setImgSrc(imageCache.get(url)!)
        return
      }

      if (url?.toLowerCase().endsWith('.heic')) {
        setIsLoading(true)
      }

      try {
        if (url?.toLowerCase().endsWith('.heic')) {
          const response = await fetch(url)
          const blob = await response.blob()
          const conversionResult = await heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.3,
          })
          const jpegUrl = URL.createObjectURL(conversionResult as Blob)
          imageCache.set(url, jpegUrl)
          setImgSrc(jpegUrl)
        } else {
          imageCache.set(url, url)
          setImgSrc(url)
        }
      } catch (error) {
        console.error('Error converting HEIC:', error)
        setImgSrc('/media/icons/duotune/communication/com006.svg')
      } finally {
        setIsLoading(false)
      }
    }

    if (imageUrl) {
      convertHeicToJpeg(imageUrl)
    }
  }, [imageUrl])

  if (isLoading) {
    return (
      <div
        style={{height: '350px'}}
        className='d-flex align-items-center justify-content-center w-100 mt-4'
      >
        <span className='spinner-border spinner-border-sm text-primary'></span>
      </div>
    )
  }

  return (
    <img
      src={imgSrc}
      style={{objectFit: 'contain'}}
      height={'350px'}
      className='card-img-top rounded-3'
      alt='...'
      onError={(e: any) => {
        e.target.src = '/media/icons/duotune/communication/com006.svg'
      }}
    />
  )
}

const Media: FC<Props> = () => {
  const {Id} = useParams()

  const {isLoading, data} = useProviderMedia(Id as any)
  console.log('🚀 ~ data:', data)
  const [item, setItem]: any = React.useState()
  // console.log("🚀 ~ file: Media.tsx:40 ~ data:", data)

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <div
        style={{minHeight: '50vh'}}
        className={`card mb-5 mb-xl-10 py-8 ${
          data?.length === 0 && 'd-flex justify-content-center'
        }`}
      >
        {isLoading ? (
          <div className='d-flex justify-content-center my-5'>
            <div className='spinner-grow text-dark' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='row mx-3 g-4'>
            {data?.length === 0 ? (
              <div className='text-center mt-4 mb-5'>
                <h3 className='text-muted'>No matching records found :(</h3>
              </div>
            ) : (
              data?.map((u: any, idx: any) => (
                <div key={idx} onClick={handleOpen} className='col-4 pointer'>
                  <div onClick={() => setItem(u)} className='border border-2 rounded'>
                    <ProfileImage imageUrl={u?.thumbnail} />
                    <div className='card-body py-4'>
                      <div className='row'>
                        <div className='col-12 px-0 h-50px overflow-auto'>
                          {u.tags?.length > 0 &&
                            u.tags.map((tag: any, idx: React.Key) => (
                              <h5 key={idx} className='card-title badge badge-secondary fs-5 me-2'>
                                {tag.service?.name}
                              </h5>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='px-8 pb-12 pt-0'>
          <div className='text-end mb-2'>
            {/* begin::Modal title */}
            {/* <h2 className='fw-bolder text-white'>Media</h2> */}
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={handleClose}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{cursor: 'pointer'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <div className='mx-4 runded-0 bg-white'>
            <div className='row g-0'>
              <div className='col-md-7 bg-secondary rounded-5'>
                <ProfileImage imageUrl={item?.thumbnail} />
              </div>
              <div className='col-md-5 py-3 ps-5'>
                <div className='row mt-1'>
                  <div className='col'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold text-muted mb-2'>Created At:</div>
                      {item?.dateTime && <p className='fs-6 fw-bold'>{makeDate(item?.dateTime)}</p>}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold text-muted mb-2'>Address</div>
                      <p className='fs-6 fw-bold'>{item?.location ? item?.location : NA}</p>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold text-muted mb-2'>Latitude:</div>
                      <p className='fs-6 fw-bold'>
                        {item?.latitude === 'null' ? NA : item?.latitude}
                      </p>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold text-muted mb-2'>Longitude:</div>
                      <p className='fs-6 fw-bold'>
                        {item?.longitude === 'null' ? NA : item?.longitude}
                      </p>
                    </div>
                  </div>
                  <div className='fw-bold text-muted mb-2 mt-4'>Tags:</div>
                  <div className='mt-auto overflow-auto'>
                    {item?.tags?.length > 0 &&
                      item?.tags.map((tag: any, idx: React.Key) => (
                        <>
                          <h5 key={idx} className='card-title badge badge-secondary fs-5 me-2 mb-2'>
                            {tag.service?.name}
                          </h5>
                        </>
                      ))}
                  </div>
                  <div className='fw-bold text-muted mb-2 mt-4'>Caption:</div>
                  <div className='mt-auto overflow-auto'>{item?.caption ? item?.caption : NA}</div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default Media
