import {MDBDataTableV5} from 'mdbreact'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import {formatLocalTime} from '../../../util/helper'
import {DeleteIcon, LockIcon, ViewIcon} from '../../modules/common/TableIcons'
import {Link, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../store'
import {useSelector} from 'react-redux'
import PasswordResetModal from '../profile/components/PasswordResetModal'
import {ToggleState} from '../Provider/modals/type'
import {Box, Skeleton, Tab, Tabs} from '@mui/material'
import {transactionSelector} from '../../reducers/transactionsReducer'
import {
  getAllPaymentSummaryAction,
  paymentSummaryPaginationSelector,
  paymentSummarySelector,
  paymentSummaryStatusSelector,
  paymentSummaryTotalAmountSelector,
} from '../../reducers/paymentSummaryReducer'
import MUITable from '../../modules/common/MUITable'
import {PaymentSummaryFilters} from './components/PaymentSummaryFilters'
import {useMemberList} from '../Member/hooks/useMemberQuery'
import {useProviderSList} from '../Provider/hooks/useProviderQuery'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

type ProviderRow = {
  profilePic: string
  firstName: string
  lastName: string
  phone: string
  businessName: string
  status: string
  id: string
  timezone: string
  isVerified: boolean
}

type MemberRow = {
  profilePic: string
  firstName: string
  lastName: string
  phone: string
  lastLogin: any
  status: string
  id: string
  timezone: string
  isVerified: boolean
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  )
}

const PaymentsSummary = () => {
  const dispatch: any = useAppDispatch()

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const paymentSummary = useSelector(paymentSummarySelector)
  const status = useSelector(paymentSummaryStatusSelector)
  const totalAmount = useSelector(paymentSummaryTotalAmountSelector)
  const pagination = useSelector(paymentSummaryPaginationSelector)

  const [searchParams, setSearchParams] = useState<any>({})

  const [initTable, setInitTable] = useState({
    columns: [
      {
        label: 'Provider Name',
        id: 'providerName',
        numeric: false,
        disablePadding: true,
      },
      {
        label: 'Client Name',
        id: 'clientName',
        numeric: false,
        disablePadding: true,
      },
      {
        label: 'Appointment Date',
        id: 'appointmentDate',
        numeric: false,
        disablePadding: true,
      },
      // {
      //   label: 'Fee',
      //   id: 'fee',
      //   numeric: true,
      //   disablePadding: true,
      // },
      {
        label: 'Discount',
        id: 'discount',
        numeric: true,
        disablePadding: true,
      },
      {
        label: 'Misc fee',
        id: 'miscFee',
        numeric: true,
        disablePadding: true,
      },
      {
        label: 'Reservation fee',
        id: 'reservationFee',
        numeric: true,
        disablePadding: true,
      },
      {
        label: 'Appointment Status',
        id: 'appointmentStatus',
        numeric: false,
        disablePadding: true,
      },
      {
        label: 'Payment Status',
        id: 'status',
        numeric: false,
        disablePadding: true,
      },
      {
        label: 'Payment Type',
        id: 'paymentType',
        numeric: true,
        disablePadding: true,
      },
      // {
      //   label: 'Tax',
      //   id: 'tax',
      //   numeric: true,
      //   disablePadding: true,
      // },
      {
        label: 'Tip',
        id: 'tip',
        numeric: true,
        disablePadding: true,
      },
      {
        label: 'Total price',
        id: 'totalPrice',
        numeric: true,
        disablePadding: true,
      },
      {
        label: 'Grand total',
        id: 'grandTotal',
        numeric: true,
        disablePadding: true,
      },
      // {
      //   label: 'Action',
      //   field: 'action',
      //   sort: 'disabled',
      //   width: 100,
      // },
    ],
    rows: [],
  })

  const memberListSelector = (res: any) => {
    let rows: never[] = []

    res?.clients?.forEach((element: any) => {
      const item: MemberRow = {
        profilePic: element?.profileImage,
        firstName: element?.firstName,
        lastName: element?.lastName,
        phone: element?.mobileNo,
        lastLogin: element?.lastLogin,
        status: element?.status,
        timezone: element?.timezone,
        isVerified: element?.isMobileVerified,
        id: element?.id,
      }
      rows.push(item as never)
    })

    return rows
  }

  const {data: MemberData} = useMemberList({
    func: memberListSelector,
  })

  const providerListSelector = (res: any) => {
    let rows: never[] = []

    res?.providers?.forEach((element: any) => {
      const item: ProviderRow = {
        profilePic: element?.profileImage,
        firstName: element?.firstName,
        lastName: element?.lastName,
        phone: element?.mobileNo,
        businessName: element?.businessName,
        status: element?.status,
        timezone: element?.timezone,
        isVerified: element?.isMobileVerified,
        id: element?.id,
      }
      rows.push(item as never)
    })

    return rows
  }

  const {data: providerData} = useProviderSList({
    func: providerListSelector,
  })

  useEffect(() => {
    dispatch(
      getAllPaymentSummaryAction({
        ...searchParams,
        perPage: perPage,
        page: page,
      })
    )

    if (searchParams?.status === 'refunded') {
      setInitTable({
        ...initTable,
        columns: [
          ...initTable.columns,
          {
            label: 'Refunded Amount',
            id: 'refundedAmount',
            numeric: false,
            disablePadding: true,
          },
        ],
      })
    } else {
      setInitTable({
        ...initTable,
        columns: initTable.columns.filter((column) => column.id !== 'refundedAmount'),
      })
    }
  }, [page, searchParams, perPage])

  useEffect(() => {
    if (paymentSummary) {
      const list: any = paymentSummary?.map((item: any) => {
        return {
          providerName: item.provider?.id
            ? item.provider?.firstName + ' ' + item.provider?.lastName
            : item.provider?.firstName + ' ' + item.provider?.lastName + ' (deleted)',
          clientName: item.client?.id
            ? item.client?.firstName + ' ' + item.client?.lastName
            : item.client?.firstName + ' ' + item.client?.lastName + ' (deleted)',
          appointmentDate: item.appointmentDate,
          // fee: item.fee,
          discount: item.discount,
          appointmentStatus: item.status,
          status:
            item.isPaid === 0
              ? 'Pending'
              : item.isPaid === 1 || item.isPaid === 5
              ? 'Completed'
              : item.isPaid === 3
              ? 'refunded'
              : item.isPaid === 2
              ? 'Reservation fee paid'
              : null,
          miscFee: item.miscFee,
          reservationFee: item.reservationFee,
          paymentType: item.isPaid === 1 ? 'Card' : item.isPaid === 5 ? 'Cash' : 'Unpaid',
          // tax: item.tax,
          tip: item.tip,
          totalPrice: item.totalPrice,
          grandTotal: item.grandTotal,
          refundedAmount: item.refundedAmount,
          // action: [
          //   <ViewIcon active={true} path={`/transaction-types/${item.id}`} />,
          //   <DeleteIcon byClick={() => handleOpenModal('d-modal', item.id)} path='#' />,
          // ],
        }
      })
      setInitTable((prevTable) => {
        return {
          ...prevTable,
          rows: list,
        }
      })
    }
  }, [paymentSummary])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <h2 className='py-3 mb-2'>Payments Summary</h2>
          <div className='row'>
            <div className='col-12 d-flex justify-content-end align-items-center gap-2'>
              {status === 'loading' ? (
                <div className='w-25'>
                  <Skeleton animation='pulse' />
                </div>
              ) : (
                <h3>Total Amount: {totalAmount}</h3>
              )}
              <PaymentSummaryFilters
                memberData={MemberData}
                setPage={setPage}
                providerData={providerData}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
          }}
          className='mb-5 mb-xl-10'
        >
          {status === 'loading' ? (
            <TableLoading />
          ) : (
            <MUITable
              data={{table: initTable, pagination: pagination}}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          )}
        </div>
      </div>
    </>
  )
}

export {PaymentsSummary}
