import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../../_metronic/partials'
import {useProviderProfile} from '../../../hooks/useProviderQuery'
import {useParams} from 'react-router-dom'
import Info from '../../../../../modules/common/Info'
import SquareState, {ArrowDirections} from '../../../../../modules/common/SquareState'
import QRCode from 'react-qr-code'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import axios from 'axios'
import heic2any from 'heic2any'

const imageCache = new Map<string, string>()

const ProfileImage = ({imageUrl}: {imageUrl: string}) => {
  const [imgSrc, setImgSrc] = useState(
    imageCache.get(imageUrl) || toAbsoluteUrl('/media/avatars/blank.png')
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const convertHeicToJpeg = async (url: string) => {
      if (!url) return

      if (imageCache.has(url)) {
        setImgSrc(imageCache.get(url)!)
        return
      }

      if (url.toLowerCase().endsWith('.heic')) {
        setIsLoading(true)
      }

      try {
        if (url.toLowerCase().endsWith('.heic')) {
          const response = await fetch(url)
          const blob = await response.blob()
          const conversionResult = await heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.3,
          })
          const jpegUrl = URL.createObjectURL(conversionResult as Blob)
          imageCache.set(url, jpegUrl)
          setImgSrc(jpegUrl)
        } else {
          imageCache.set(url, url)
          setImgSrc(url)
        }
      } catch (error) {
        console.error('Error converting HEIC:', error)
        setImgSrc(toAbsoluteUrl('/media/avatars/blank.png'))
      } finally {
        setIsLoading(false)
      }
    }

    if (imageUrl) {
      convertHeicToJpeg(imageUrl)
    }
  }, [imageUrl])

  // if (isLoading) {
  //   return (
  //     <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border d-flex align-items-center justify-content-center'>
  //       <span className='spinner-border spinner-border-sm text-primary'></span>
  //     </div>
  //   )
  // }

  return (
    <img
      src={imgSrc}
      alt='provider avatar'
      style={{objectFit: 'contain'}}
      onError={(e: any) => {
        e.target.src = toAbsoluteUrl('/media/avatars/blank.png')
      }}
    />
  )
}

const Overview = () => {
  const {Id} = useParams()
  const {isLoading, data, isError, error, isFetching, refetch} = useProviderProfile(Id)
  const [link, setLink] = useState('')

  const downloadQRCodeAsPDF = () => {
    const qrCodeElement: any = document.querySelector('#qrcode') // Replace 'qrcode' with the actual ID of your QRCode component

    html2canvas(qrCodeElement).then((canvas) => {
      const image = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imageWidth = canvas.width * 1.5
      const imageHeight = canvas.height * 1.5
      const offsetX = (pdfWidth - imageWidth) / 2 // Calculate horizontal offset for centering
      const offsetY = (pdfHeight - imageHeight) / 4 // Calculate vertical offset for centering

      pdf.addImage(image, 'PNG', offsetX, offsetY, imageWidth, imageHeight)

      pdf.save('qrcode.pdf')
    })
  }

  const createDeepLink = async () => {
    const link = await axios({
      method: 'post',
      url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDlk11w31BH-O8oT8Loocx4YQQrbzOrgQ8',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://kazzahpro.page.link',
          link: `https://www.kazzah.com/?proId=${Id}`,
          iosInfo: {
            iosAppStoreId: process.env.REACT_APP_MEMBER_APP_STORE_ID,
            iosBundleId: process.env.REACT_APP_MEMBER_PACKAGE_ID,
          },
          androidInfo: {
            androidPackageName: process.env.REACT_APP_MEMBER_PACKAGE_ID,
          },
        },
        suffix: {
          option: 'SHORT',
        },
      },
    })
    setLink(link.data.shortLink)

    return link.data.shortLink
  }

  useEffect(() => {
    createDeepLink()
  }, [])

  return (
    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
      <div className='me-7 mb-4'>
        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'>
          <ProfileImage
            imageUrl={
              isLoading || !data?.profileImage
                ? toAbsoluteUrl('/media/avatars/blank.png')
                : data?.profileImage
            }
          />
          <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
        </div>
      </div>

      <div className='flex-grow-1'>
        {/* top section */}
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
          <div className='d-flex flex-column'>
            {/* profile image */}
            <div className='d-flex align-items-center mb-2'>
              <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                {isLoading ? 'Loading...' : data?.username ? data?.username : data?.businessName}
              </span>
              <span>
                <KTSVG
                  path='/media/icons/duotune/general/gen026.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              </span>
            </div>

            {/* basic info */}
            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
              {data?.service?.service?.name ? (
                <Info
                  iconPath='/media/icons/duotune/communication/com006.svg'
                  text={data?.service?.service?.name}
                  isLoading={isLoading}
                />
              ) : null}
              <Info
                iconPath='/media/icons/duotune/general/gen018.svg'
                text={`${data?.state}, ${data?.city}`}
                isLoading={isLoading}
              />
              {data?.email ? (
                <Info
                  iconPath='/media/icons/duotune/communication/com011.svg'
                  text={data?.email}
                  isLoading={isLoading}
                />
              ) : null}
            </div>
          </div>

          <div className='d-flex my-4 flex-column align-items-center justify-content-center gap-2'>
            <button className='btn btn-sm btn-primary' onClick={downloadQRCodeAsPDF}>
              Download QR
            </button>
            <div
              style={{height: 'auto', margin: '0 auto', maxWidth: 64, width: '100%'}}
              id='qrcode'
            >
              <QRCode
                size={512}
                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                value={link}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>

        {/* bottom section */}
        <div className='d-flex flex-wrap flex-stack'>
          {data?.TotalEarnings ? (
            <div className='d-flex flex-column flex-grow-1 pe-8'>
              <div className='d-flex flex-wrap'>
                <SquareState title='Earning' value={data?.TotalEarnings} />
                <SquareState
                  title='Appointments'
                  value={data?.TotalAppointments ? data?.TotalAppointments : '0'}
                />
                <SquareState title='Client Served' value={data?.TotalClientServed} />
              </div>
            </div>
          ) : null}
          {data?.percentage ? (
            <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
              <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                <span className='fw-bolder fs-6'>{data?.percentage}%</span>
              </div>
              <div className='h-5px mx-3 w-100 bg-light mb-3'>
                <div
                  className='bg-success rounded h-5px'
                  role='progressbar'
                  style={{width: `${data?.percentage}%`}}
                ></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Overview
