import moment from 'moment'
import {useProviderProfile} from '../hooks/useProviderQuery'
import InfoWrapper from '../../../modules/common/SectionInfo'
import {useParams} from 'react-router-dom'

// Provider

const Profile = () => {
  const {Id} = useParams()
  const {isLoading, data} = useProviderProfile(Id)

  const makeDate = (date: Date | undefined) => {
    if (date) {
      return moment(date).format('MM-DD-yyyy')
    } else {
      return ''
    }
  }

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-body px-9 py-2'>
        <div className='mt-4'>
          <InfoWrapper title='Personal Information'>
            <div className='row'>
              <div className='col-lg-9 col-12'>
                <div className='row'>
                  {data?.firstName ? (
                    <>
                      <div className='col-lg-4'>
                        <div className='fw-bold text-muted mb-2'>First Name</div>
                        <p className='fs-6 mb-7 fw-bold'>{data?.firstName}</p>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fw-bold text-muted mb-2'>Last Name</div>
                        <p className='fs-6 mb-7 fw-bold'>{data?.lastName}</p>
                      </div>
                    </>
                  ) : (
                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>Business Name</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.businessName}</p>
                    </div>
                  )}
                  <div className='col-lg-4'>
                    <div className='fw-bold text-muted mb-2'>Mobile No.</div>
                    <p className='fs-6 mb-7 fw-bold'>{data?.mobileNo}</p>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fw-bold text-muted mb-2'>Username</div>
                    <p className='fs-6 mb-7 fw-bold'>{data?.username}</p>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fw-bold text-muted mb-2'>User ID</div>
                    <p className='fs-6 mb-7 fw-bold'>{data?.id}</p>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fw-bold text-muted mb-2'>Email</div>
                    <p className='fs-6 mb-7 fw-bold'>{data?.email}</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-3'>
                <div className='fw-bold text-muted mb-2'>Bio</div>

                {data?.bio === null ? (
                  <span className=' text-muted  fs-8 d-block mb-7'>No bio available</span>
                ) : (
                  <div
                    style={{height: '110px'}}
                    className='fw-bold fs-6 d-block mb-7 overflow-auto'
                  >
                    {data?.bio}
                  </div>
                )}
              </div>
            </div>
          </InfoWrapper>

          <InfoWrapper title='Address' bar='up'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>State</div>
                    <span className='fw-bold fs-6 d-block mb-7'>{data?.state}</span>
                  </div>

                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>City</div>
                    <span className='fw-bold fs-6 d-block mb-7'>{data?.city}</span>
                  </div>

                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Street</div>
                    <span className='fw-bold fs-6 d-block mb-7'>{data?.street}</span>
                  </div>

                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Zip</div>
                    <span className='fw-bold fs-6 d-block mb-7'>{data?.zip}</span>
                  </div>

                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Latitude / Longitude</div>
                    <span className='fw-bold fs-6 d-block mb-7'>
                      {data?.latitude ? data?.latitude : '--'}
                      <span className='fw-lighter mx-2'>/</span>
                      {data?.longitude ? data?.longitude : '--'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </InfoWrapper>

          <InfoWrapper title='Basic info' bar='up'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Business Name</div>
                    <p className='fs-6 mb-7 fw-bold'>
                      {data?.businessName === null ? 'NA' : data?.businessName}
                    </p>
                  </div>
                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Last Login at</div>
                    <span className='badge badge-secondary'>{makeDate(data?.lastLogin)}</span>
                  </div>
                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Created at</div>
                    <span className='badge badge-secondary'>{makeDate(data?.createdAt)}</span>
                  </div>
                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Verification Code Sent at</div>
                    <span className='badge badge-secondary'>
                      {makeDate(data?.verificationCodeSentAt)}
                    </span>
                  </div>
                  <div className='col-lg-3'>
                    <div className='fw-bold text-muted mb-2'>Referral Code</div>
                    <span className='fw-bold fs-6 d-block mb-7'>{data?.referralCode}</span>
                  </div>
                </div>
              </div>
            </div>
          </InfoWrapper>
        </div>
      </div>
    </div>
  )
}

export default Profile
